import { css, Global, withTheme } from '@emotion/react'
import React from 'react'

const GlobalStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      body {
        color: ${theme.colors.dark.base.hex};
      }
    `}
  />
))

export default GlobalStyles
