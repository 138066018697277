import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const PackageCardStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-package-card {
        .title {
          text-transform: unset !important;
        }
      }
    `}
  />
))

export default PackageCardStyles
