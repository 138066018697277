import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const FormStyles = withTheme(() => (
  <Global
    styles={css`
      .dpr-form-container {
        padding-right: calc((100% - 1200px) / 2);
        padding-left: calc((100% - 1200px) / 2);
        padding-top: 88px;
        padding-bottom: 88px;
        display: flex;
        justify-content: center;
        background-color: #eef3f5;
      }
    `}
  />
))

export default FormStyles
